<template>
  <v-container
    id="predicao-historico"
    fluid
    tag="section"
  >
    <add-fixed-button @clicked="addUser()" />

    <v-card-text style="height: 100px; position: relative">

    </v-card-text>

    <v-row>
      <v-col 
        cols="12"
      >
        <base-material-card
          color="indigo"
          icon="mdi-account"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              Usuários
            </div>
          </template>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto"
            label="Pesquisar"
            hide-details
            single-line
            style="max-width: 250px;"
          />

          <v-divider class="mt-3" />

          <v-data-table
            :headers="headers"
            :items="items"
            :search.sync="search"
            :sort-desc="[false, true]"
            multi-sort
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
          >
            <template v-slot:item="row">
              <tr>
                <td class="button">{{row.item.id}}</td>
                <td class="button">{{row.item.name}}</td>
                <td class="button">{{row.item.email}}</td>
                <td class="button">{{row.item.admin}}</td>
                <td class="button">{{row.item.modified}}</td>
                <td class="button">{{row.item.created}}</td>
                <td>
                  <v-btn
                    class="mr-2"
                    outlined
                    fab
                    x-small
                    color="info"
                    @click="editUser(row.item.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="error"
                    @click="toggleActive(row.item)"
                  >
                    <v-icon v-if="row.item.active">mdi-account-cancel</v-icon>
                    <v-icon v-else>mdi-account-plus</v-icon>
                  </v-btn>
                        
                </td>

              </tr>
            </template>

          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import UsersService from '@/services/UsersService'
  import dayjs from 'dayjs'

  export default {
    name: 'StocksIndex',

    components: {
      AddFixedButton: () => import('@/components/base/AddFixedButton'),
    },

    data () {
      return {
        search: '',
        items: [],
        headers: [
        {
          text: '#',
          value: 'id',
        },
        {
          text: 'Nome',
          value: 'name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Admin',
          value: 'admin',
        },
        {
          text: 'Modificado',
          value: 'modified',
        },
        {
          text: 'Criado',
          value: 'created',
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
        },
      ],
      }
    },

    mounted () {
      this.getAllUsers()
    },

    computed: {
    },

    methods: {

      addUser () {
        this.$router.push({name: 'Criar Usuário'})
      },

      editUser (id) {
        this.$router.push('/users/edit/'+id)
      },

      toggleActive (user) {
        UsersService.toggleActive(user.id)
        .then(response => {
          user.active = !user.active
          this.$toast.success('Usuário alterado.', '',{position:'topRight'})
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao alterar usuário.', '',{position:'topRight'}) 
        })
      },

      getAllUsers () {
        UsersService.getUsers()
        .then(response => {
          this.items = []
          let obj = {}
          response.data.users.forEach(element => {

            obj = {
              id: element.id,
              name: element.name,
              email: element.email,
              admin: element.admin ? 'Sim' : 'Não',
              active: element.active,
              created: dayjs(element.created).format('DD/MM/YY'),
              modified: dayjs(element.modified).format('DD/MM/YY'),
            }
            this.items.push(obj);
          });
          //console.log(this.items)
        })
        .catch(error => console.log(error))
      },
    },

  }
</script>
